import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "volviendo-a-entrar-al-reino",
      "style": {
        "position": "relative"
      }
    }}>{`Volviendo a entrar al reino`}<a parentName="h1" {...{
        "href": "#volviendo-a-entrar-al-reino",
        "aria-label": "volviendo a entrar al reino permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "porqué",
      "style": {
        "position": "relative"
      }
    }}>{`Porqué`}<a parentName="h2" {...{
        "href": "#porqu%C3%A9",
        "aria-label": "porqué permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Hemos aprendido las formas básicas en que podemos atacar los smart contracts. Hemos implementado nuestros propios contratos para practicar opuestos complementarios en público. Hemos profundizado en el almacenamiento público y permanente de todas las cosas. Ahora es el momento de recurrir a la vulnerabilidad más clásica que existe y volver a entrar conscientemente en el truco que casi lo desentraña todo.`}</p>
    <p>{`Jugaremos por el trono, subiremos al último piso y volveremos a firmar un contrato para reclamarlo todo. Este es realmente el clímax. ¿Quién diría que los smart contracts podrían presentar una telenovela tan dramática?`}</p>
    <h2 {...{
      "id": "preparación",
      "style": {
        "position": "relative"
      }
    }}>{`Preparación`}<a parentName="h2" {...{
        "href": "#preparaci%C3%B3n",
        "aria-label": "preparación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Asegúrate de que todavía le quede algo de Sepolia ETH en su cuenta.`}</li>
      <li parentName="ol">{`Lee sobre el truco de DAO.`}</li>
      <li parentName="ol">{`Siéntate y respira tranquilamente durante al menos 20 minutos. Utiliza esta `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=g_LNp8xY1YM"
        }}>{`música`}</a>{` si te ayuda. O `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=b5H3b_Hh0Lw"
        }}>{`este`}</a>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "aplicación",
      "style": {
        "position": "relative"
      }
    }}>{`Aplicación`}<a parentName="h2" {...{
        "href": "#aplicaci%C3%B3n",
        "aria-label": "aplicación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Vamos a investigar hacks más avanzados que utilizan funciones alternativas (o la falta de ellas) y patrones en funciones que no protegen adecuadamente contra ellas. También veremos algunas armas de fuego más en Solidity, como modificadores de alcance engañosos e interfaces de interferencia.`}</p>
    <p>{`Al redactar algunos contratos de ataque más avanzados, aprenderás a escribir tus propios contratos y a hacerlo de forma más segura, al mismo tiempo. Si el tiempo lo permite, también echaremos un vistazo al depurador de Remix e intentaremos realizar una llamada de función para rastrear lo que sucede en el EVM.`}</p>
    <h2 {...{
      "id": "resumen",
      "style": {
        "position": "relative"
      }
    }}>{`Resumen`}<a parentName="h2" {...{
        "href": "#resumen",
        "aria-label": "resumen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`En esta sesión habrás aprendido:`}</p>
    <ol>
      <li parentName="ol">{`Formas más complejas de utilizar funciones alternativas para explotar código deficiente.`}</li>
      <li parentName="ol">{`Qué tipo de patrones evitar al redactar tus propios contratos.`}</li>
      <li parentName="ol">{`Cómo utilizar más funciones de Remix para ayudarte en tu propio desarrollo.`}</li>
      <li parentName="ol">{`Por qué leer código es un asunto de múltiples capas y cuán matizada es realmente la confianza.`}</li>
      <li parentName="ol">{`Cómo pensar en el tipo de patrones lógicos que los buenos desarrolladores tienen por costumbre.`}</li>
    </ol>
    <h2 {...{
      "id": "grabación",
      "style": {
        "position": "relative"
      }
    }}>{`Grabación`}<a parentName="h2" {...{
        "href": "#grabaci%C3%B3n",
        "aria-label": "grabación permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/9atqMRJLEoU" mdxType="Video" />
    <p><img parentName="p" {...{
        "src": "/images/build/tat_tvam_asi.jpg",
        "alt": "It Is Within You"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      